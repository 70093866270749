import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
function RecaptchaNoticeComponent() {
	const { t } = useTranslation();
	return (
		<>
			{null}
			{/* <Typography variant="caption">
				{t('legals.recaptchaDisclaimer.part1')} 
				<a href="https://policies.google.com/privacy" color="inherit" underline="none" >{t('legals.recaptchaDisclaimer.privacyPolicy')}</a>{t('legals.recaptchaDisclaimer.part2')} 
				<a href="https://policies.google.com/terms" color="inherit" underline="none" >{t('legals.recaptchaDisclaimer.terms')}</a>{t('legals.recaptchaDisclaimer.part3')}
			</Typography> */}
		</>
	);
  }


export default RecaptchaNoticeComponent;