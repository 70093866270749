
import { httpsCallable } from "firebase/functions";
import {  functions } from "../../firebase";
export async function getRecommendedDiscussions(userTwoLetterISOLanguage){
	const getrecommendeddiscussionslistfromcloudspanner = httpsCallable(functions, 'getrecommendeddiscussionslistfromcloudspanner');
	return await getrecommendeddiscussionslistfromcloudspanner({userTwoLetterISOLanguage: userTwoLetterISOLanguage})
	.then((result) => {
		const data = result.data;
		const dataInLocalFormat =  data !== null ? data.map((row) => {
			return {
				topic: {
					title: row.Title,
					topicId: row.TopicId
				},
				discussion: {
					discussionId: row.DiscussionId,
					timestamp: row.UnixMillisDiscussionTimestamp,
					followers: row.DiscussionFollowers,
					ratingsSum: row.RatingsSum,
					ratingsCounter: row.RatingsCounter,
					postsCounter: row.PostsCounter,
					lastVisitedTimestamp: row.UnixMillisRecentDiscussionsLastVisitedTimestamp,
					lastPostTimestamp: row.UnixMillisDiscussionLastPostTimestamp
				}
			}
		}) : []
		return dataInLocalFormat
	})
	.catch((error) => {
	});
}
export async function getRecommendedTopics(userTwoLetterISOLanguage){
	const getrecommendedtopiclistfromcloudspanner = httpsCallable(functions, 'getrecommendedtopiclistfromcloudspanner');
	return await getrecommendedtopiclistfromcloudspanner({userTwoLetterISOLanguage: userTwoLetterISOLanguage})
	.then((result) => {
		const data = result.data;
		const dataInLocalFormat =  data !== null ? data.map((row) => {
			return {
				topic: {
					title: row.Title,
					topicId: row.TopicId
				},
			}
		}) : []
		return dataInLocalFormat
	})
	.catch((error) => {
	});
}