import React, {useState, useEffect} from 'react';
import './RecommendedDiscussionsComponent.css'
import Typography from '@mui/material/Typography';

import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import StarIcon from '@mui/icons-material/Star';

import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import {  RECOMMENDED_DISCUSSIONS_ITEM_HEIGHT} from '../../utils/Constants';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';

import { useTranslation } from 'react-i18next';
import { getRecommendedDiscussions, getRecommendedTopics } from '../../firebaseFunctions/spannerFunctions/recommendedFunctions';

import { useNavigate } from 'react-router-dom';

import { styled } from '@mui/system';
const StyledButton = styled(Button)(({ theme }) => ({
	textTransform: 'none!important',
	padding: '0!important',
	width: 'auto!important',
	minWidth: 'unset!important',
	'&:hover': {
		backgroundColor: 'transparent!important',
	},
	fontFamily: theme.typography.fontFamily,
	fontSize: theme.typography.fontSize,
	fontWeight: theme.typography.fontWeight,
	lineHeight: theme.typography.lineHeight,
	letterSpacing: theme.typography.letterSpacing
}))
export default function RecommendedDiscussionsComponent(props) {
	const { t, i18n } = useTranslation();

  const [recommendedList, setRecommendedList] = useState([])
  const [loading, setLoading] = useState(true)
  const [recommendedTopicsList, setRecommendedTopicsList] = useState([])
  const [discussionList, setDiscussionList] = useState(false) //start with recommended topics list

  useEffect(() => {  
    // Anything in here is fired on component mount.	

    // current user language
    const currentLang = i18n.language
    getRecommendedDiscussions(currentLang).then((res) => {
      setRecommendedList([...res])
    })

    getRecommendedTopics(currentLang).then((res) => {
      setRecommendedTopicsList([...res])
    })
    setLoading(false)
    
    return () => {
    }

  }, [])

  
  const navigate = useNavigate()  
	const handleNavigateTo = (route) => {
    navigate(route)
    window.location.reload()
	};

	const handleClickTopics = () => {
		setDiscussionList(false);
	};
	const handleClickDiscussions = () => {
		setDiscussionList(true);
	};

  return (
    <>
      <Card className="RecommendedCard" sx={{height:"100%!important", maxHeight: "100%!important", width: "inherit",
        position: 'relative',
        overflow: 'auto'
        }}>        
        <Box className="RecommendedTitle" sx={{ width:"inherit", paddingTop:"15px",paddingBottom:"15px", position: "fixed", zIndex: 2}}>
          <Stack direction='row'    sx={{
              justifyContent: "space-between",
              alignItems: "center",
            }}>
            <Typography sx={{marginLeft: "8px"}} variant='body1'>{t('recommendedDiscussionsComponent.recommended')}</Typography>
            <Stack direction='row'spacing={1} sx= {{ marginTop: "4px", marginRight: "6px"}}
              justifyContent="center"
              alignItems="center">
                <StyledButton onClick={() => handleClickTopics()} sx={{ textDecoration: discussionList ? 'none' : 'underline' }}>{t('recommendedDiscussionsComponent.topics')}</StyledButton>
                <StyledButton onClick={() => handleClickDiscussions()} sx={{ textDecoration: discussionList ? 'underline' : 'none' }}>{t('recommendedDiscussionsComponent.discussions')}</StyledButton>
            </Stack>
          </Stack>
        </Box> 
          {
            loading ? 
              <Box sx={{ display: 'flex',
                alignContent: 'center', 
                height: "100%!important",
                alignItems:"center",
                justifyContent:"center"}}>
                <CircularProgress />
              </Box> 
              :
                discussionList ? 
                  recommendedList.length !== 0 ? 
                    <>
                      <List 
                        sx={{ 
                          paddingTop: "0!important",
                          height: "inherit!important"
                        }}>
                        <Box sx={{ backgroundColor: "white", width:"inherit", height: "50px"}}/>
                        {
                          recommendedList.map((discussion) => {
                            return (
                              <>	
                                <ListItem 
                                  justifyContent="flex-start"
                                  alignItems="center"
                                  spacing={0}
                                  sx={{height: RECOMMENDED_DISCUSSIONS_ITEM_HEIGHT, paddingLeft: "6px"}}>
                                  <Stack direction='row'
                                      justifyContent="center"
                                      alignItems="center">
                                    <Stack direction='column' sx= {{ marginTop: "4px", marginRight: "6px"}}
                                      justifyContent="center"
                                      alignItems="center">
                                      <StarIcon fontSize='small' style={{ marginTop: "0px", opacity: 0.55, color: '#faaf00' }}/>
                                      <Typography variant="caption">                                  
                                        {(discussion.discussion.ratingsSum) === 0 ? 0 : (discussion.discussion.ratingsSum/discussion.discussion.ratingsCounter).toFixed(1)}
                                      </Typography>  
                                    </Stack>
                                    <Button sx = {{
                                          textTransform: 'none!important',
                                          padding: '0!important',
                                          justifyContent: 'flex-start', textAlign: 'left', '&:hover': { backgroundColor: 'transparent' }
                                      }} onClick={() => handleNavigateTo("/topic/" + discussion.topic.topicId + "/discussion/" + discussion.discussion.discussionId)} color="inherit" underline="hover">
                                        <Typography variant="subtitle1" sx={{
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          display: "-webkit-box",
                                          WebkitLineClamp: "2",
                                          WebkitBoxOrient: "vertical"}}>
                                          {discussion.topic.title}
                                        </Typography>  
                                    </Button>	
                                  </Stack>
                                </ListItem>
                                <Divider />
                              </>
                            )
                          })
                        }
                      </List>
                    </>
                  :
                    <>
                      <Box sx={{ display: 'flex',
                        alignContent: 'center', 
                        height: "100%!important",
                        alignItems:"center",
                        justifyContent:"center"}}>
                        <Typography variant='caption'>{t('recommendedDiscussionsComponent.noResults')}</Typography>
                      </Box>
                    </>
                :
                recommendedTopicsList.length !== 0 ? 
                  <>
                    <List 
                      sx={{ 
                        paddingTop: "0!important",
                        height: "inherit!important"
                      }}>
                      <Box sx={{ backgroundColor: "white", width:"inherit", height: "50px"}}/>
                      {
                        recommendedTopicsList.map((topic) => {
                          return (
                            <>	
                              <ListItem 
                                justifyContent="flex-start"
                                alignItems="center"
                                spacing={0}
                                sx={{height: RECOMMENDED_DISCUSSIONS_ITEM_HEIGHT, paddingLeft: "6px"}}>
                                <Button sx = {{
                                  	textTransform: 'none!important',
                                    padding: '0!important',
                                    justifyContent: 'flex-start', textAlign: 'left', '&:hover': { backgroundColor: 'transparent' }
                                }} onClick={() => handleNavigateTo("/topic/" + topic.topic.topicId)} color="inherit" underline="hover">
                                  <Tooltip title={<>{topic.topic.title}</>}>
                                    <Typography variant="subtitle1" sx={{
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      display: "-webkit-box",
                                      WebkitLineClamp: "2",
                                      WebkitBoxOrient: "vertical"}}>
                                      {topic.topic.title}
                                    </Typography>  
                                  </Tooltip>
                                </Button>	
                              </ListItem>
                              <Divider />
                            </>
                          )
                        })
                      }
                    </List>
                  </>
                :
                  <>
                    <Box sx={{ display: 'flex',
                      alignContent: 'center', 
                      height: "100%!important",
                      alignItems:"center",
                      justifyContent:"center"}}>
                      <Typography variant='caption'>{t('recommendedDiscussionsComponent.noResults')}</Typography>
                    </Box>
                  </> 
          }
      </Card>
    </>
  );
}

