import React from 'react';
import './PoliciesNotificationNonModalDialog.css'
import Stack from '@mui/material/Stack';
import TrapFocus from '@mui/material/Unstable_TrapFocus';
import Paper from '@mui/material/Paper';
import Fade from '@mui/material/Fade';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import { auth } from "../../firebase";
import {Link as RouterLink} from 'react-router-dom'
import Link from '@mui/material/Link';
import RecaptchaNoticeComponent from '../RecaptchaNoticeComponent/RecaptchaNoticeComponent';



export default function PoliciesNotificationNonModalDialog() {	
	const [bannerOpen, setBannerOpen] = React.useState(true);

	const closeBanner = () => {
	  setBannerOpen(false);
	};
	const { t } = useTranslation();
  
	return (
		<>
			{auth.currentUser ?
			null
			:
			<div>
			  <TrapFocus open disableAutoFocus disableEnforceFocus>
				<Fade appear={false} in={bannerOpen}>
				  <Paper
					role="dialog"
					aria-modal="false"
					aria-label="policies-banner"
					square
					variant="outlined"
					tabIndex={-1}
					sx={{
					  position: 'fixed',
					  bottom: 0,
					  left: 0,
					  right: 0,
					  m: 0,
					  p: 2,
					  borderWidth: 0,
					  borderTopWidth: 1,
					  zIndex: '1999'
					}}
				  >
					<Stack
					  direction={{ xs: 'column', sm: 'row' }}
					  sx={{ justifyContent: 'space-between', gap: 2}}
					>
					  <Box
						sx={{ flexShrink: 1, alignSelf: { xs: 'flex-start', sm: 'center' } }}
					  >
							<Typography variant="subtitle1">
								{t('legals.nonAuthenticatedUserDisclaimer')}
							</Typography>
							
							<Typography variant="caption">
								<Link component={RouterLink} to={"/flow/terms"} color="inherit" underline="none">
									{t('legals.termsAndConditions')}
								</Link>	
							</Typography>
							<br/>
							<Typography variant="caption">
								<Link component={RouterLink} to={"/flow/privacy"} color="inherit" underline="none">
									{t('legals.privacyPolicy')}
								</Link>	
							</Typography>
							<br/>
							<RecaptchaNoticeComponent/>
					  </Box>
					  <Stack
						direction={{
						  xs: 'row-reverse',
						  sm: 'row',
						}}
						sx={{
						  gap: 2,
						  flexShrink: 0,
						  alignSelf: { xs: 'flex-end', sm: 'center' },
						}}
					  >
						<IconButton  style={{marginRight: 6}} color="primary" component="label" onClick={closeBanner}>
							<CloseIcon fontSize='small'/>
						</IconButton>
					  </Stack>
					</Stack>
				  </Paper>
				</Fade>
			  </TrapFocus>
			</div>
			}
		</>
	);

}