import React, {useState, useEffect} from 'react';
import './AcceptNewPoliciesComponent.css'
import Paper from '@mui/material/Paper';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DialogActions from '@mui/material/DialogActions';
import LinearProgress from '@mui/material/LinearProgress';
import DialogContent from '@mui/material/DialogContent';
import Snackbar from '@mui/material/Snackbar';
import DialogContentText from '@mui/material/DialogContentText';
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import Stack from '@mui/material/Stack';
import { styled } from '@mui/system';
import Link from '@mui/material/Link';
import { useAuth } from '../../contexts/AuthContext'

import { useTranslation } from 'react-i18next';
import { acceptLatestPolicies } from '../../firebaseFunctions/spannerFunctions/policiesFunctions';
import { deleteUserFromCloudSpanner } from '../../firebaseFunctions/spannerFunctions/deleteFunctions';
import { getCurrentUserProfile } from '../../firebaseFunctions/spannerFunctions/profileFunctions';
import RecaptchaNoticeComponent from '../RecaptchaNoticeComponent/RecaptchaNoticeComponent';

const StyledButton = styled(Button)(({ theme }) => ({
	textTransform: 'none!important',
	padding: '0!important',
	width: 'auto!important',
	minWidth: 'unset!important',
	'&:hover': {
		backgroundColor: 'transparent!important',
	},
	fontFamily: theme.typography.fontFamily,
	fontSize: theme.typography.fontSize,
	fontWeight: theme.typography.fontWeight,
	lineHeight: theme.typography.lineHeight,
	letterSpacing: theme.typography.letterSpacing
}))
const StyledButtonCancelDelete = styled(Button)(({ theme }) => ({
	backgroundColor: '#e63030!important'
}))
const StyledButtonAccept = styled(Button)(({ theme }) => ({
	backgroundColor: '#4b6ddb!important'
}))

export default function AcceptNewPoliciesComponent() {
	const { t } = useTranslation();
	const [profile, setProfile] = useState()
		
	const navigate = useNavigate()
	const { currentUser } = useAuth()
	const [error, setError] = useState("")
	const [loading, setLoading] = useState(false)
	async function handleSubmit(event) {
		event.preventDefault()
		setLoading(true)
		const res = await acceptLatestPolicies()
		if (res === true) {
			// navigate to main page
			window.location.reload()
			setLoading(false)
		} else {
			// display error			
			setLoading(false)
			return setError(t("acceptNewPoliciesComponent.error"))
		}
	}
	const [acceptsTermsAndConditions, setAcceptsTermsAndConditions] = useState(false)
	const [readTermsAndConditions, setReadTermsAndConditions] = useState(false)
	const [openTermsAndConditionsDialog, setOpenTermsAndConditionsDialog] = useState(false);
  
	const handleClickOpenTermsAndConditionsDialog = () => {
		setOpenTermsAndConditionsDialog(true);
	};
	const handleCloseTermsAndConditionsDialog = () => {
	  setOpenTermsAndConditionsDialog(false);
	};
	const handleAcceptTermsAndConditions = () => {
		setAcceptsTermsAndConditions(true)
		handleCloseTermsAndConditionsDialog()
	}
	const [acceptsPrivacyPolicy, setAcceptsPrivacyPolicy] = useState(false)
	const [readPrivacyPolicy, setReadPrivacyPolicy] = useState(false)
	const [openPrivacyPolicyDialog, setOpenPrivacyPolicyDialog] = useState(false);
	
	const handleClickOpenPrivacyPolicyDialog = () => {
		setOpenPrivacyPolicyDialog(true);
	};
	const handleClosePrivacyPolicyDialog = () => {
		setOpenPrivacyPolicyDialog(false);
	};
	const handleAcceptPrivacyPolicy = () => {
		setAcceptsPrivacyPolicy(true)
		handleClosePrivacyPolicyDialog()
	}

	const handleTermsAndConditionsScroll = (e) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		if (scrollTop + clientHeight >= scrollHeight -1 || readTermsAndConditions) {
		  	setReadTermsAndConditions(true);
		} else {
			setReadTermsAndConditions(false);
		}
	}
	const handlePrivacyPolicyScroll = (e) => {
		const { scrollTop, scrollHeight, clientHeight } = e.target;
		if (scrollTop + clientHeight >= scrollHeight -1 || readPrivacyPolicy) {
		  	setReadPrivacyPolicy(true);
		} else {
			setReadPrivacyPolicy(false);
		}
	}

	// alternative to delete user
	const [deleteUserUpdateMessage, setDeleteUserUpdateMessage] = useState();
	const [deleteUserModalOpen, setDeleteUserModalOpen] = useState(false);
	const [deleteUserModalError, setDeleteUserModalError] = useState("")
	const [loadingUserDeletion, setLoadingUserDeletion] = useState(false)
	const [deleteUserSuccessAlertOpen, setDeleteUserSuccessAlertOpen] = useState(false);
	const handleDeleteUserModalOpen = () => {
		setDeleteUserModalError("")
		setDeleteUserModalOpen(true);
	}
	const handleDeleteUserModalClose = (event, message) => {
		if (message === 'backdropClick') {
			// cant close modal unless clicking on cancel
			return; 
		} else {		
			setDeleteUserModalOpen(false)
		}
	}
	const handleDeleteUserModalCancel = () => {
		handleDeleteUserModalClose()
	}
	const deleteUserModalHandleSubmit = (event) => {
		event.preventDefault()
		const data = new FormData(event.currentTarget);
		if(data.get('username') !== profile.username){
			setDeleteUserModalError(t("profileConfiguration.deleteUsernamesDontMatch"))
		} else {
			setLoadingUserDeletion(true)
			setError('')
			deleteUserFromCloudSpanner().then((res) => {
				if (res.success === true) {
					// refresh id token of user in order to sign out on user deletion.
					currentUser.getIdToken(true).then(() => {
						navigate("/")
					})
				} else {
					setDeleteUserUpdateMessage({error: true})
					openDeleteUserSuccessAlert()
				}
			})
		}
	}
	const openDeleteUserSuccessAlert = () => {
		setDeleteUserSuccessAlertOpen(true);
	};
  
	const deleteUserCloseSuccessAlert = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}
		setDeleteUserSuccessAlertOpen(false);
	};
	useEffect(() => {
		// Your code here, equivalent to component did mount
		setLoading(true)
		getCurrentUserProfile().then((data) => {
			setProfile(data)
			setLoading(false)
		})

	}, []);
	return (
		<>
		<Snackbar open={deleteUserSuccessAlertOpen} autoHideDuration={6000} onClose={deleteUserCloseSuccessAlert}>
			{
				deleteUserUpdateMessage && 
				(deleteUserUpdateMessage.error === true ? 
					<Alert onClose={deleteUserCloseSuccessAlert} severity="error" sx={{ width: '100%' }}>
						{t("profileConfiguration.userDeleteError")}
					</Alert>
					:
					null
				)

			}
		</Snackbar>
		<Dialog
			open={deleteUserModalOpen}
			onClose={handleDeleteUserModalClose}
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
		>
		<Box sx={{padding: "18px!important"}} component="form" noValidate onSubmit={deleteUserModalHandleSubmit} >			
			<Typography id="modal-modal-title" variant="h2" sx={{paddingBottom: "18px!important"}}>
				{t("profileConfiguration.deleteUser")}
			</Typography>		
			<Typography id="modal-modal-description" variant="body1" sx={{paddingBottom: "18px!important"}}>
				{loadingUserDeletion ?
					t("profileConfiguration.loadingUserDeletion")	
					:
					t("profileConfiguration.deleteUserDescription")
				}
			</Typography>
			{deleteUserModalError && <Alert severity='error' style={{marginTop: 8, marginBottom: 8}}>{deleteUserModalError}</Alert>}
			{loadingUserDeletion ?
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
				:
				<>
					<Grid container spacing={2}>
						<Grid item xs={12}>
						
							<TextField
								required
								spellCheck={false}
								fullWidth
								name="username"
								label={t("profileConfiguration.currentUsername")}
								id="username"															
							/>
						</Grid>
					</Grid>
					<Stack
						direction="row"
						justifyContent="space-around"
						alignItems="center"
						spacing={2}
						sx={{padding: "10px!important"}}
						>
						<Button
							type="submit"
							variant="contained"
							sx={{ mt: 3, mb: 2, width: 1/2}}
						>
							{t("profileConfiguration.delete")}
						</Button>
						<StyledButtonCancelDelete
							style={{marginTop: 7}}
							sx={{ mt: 3, mb: 2, width: 1/2}}
							variant="contained"
							color="error"
							onClick={handleDeleteUserModalCancel}
						>
							{t("cancel")}
						</StyledButtonCancelDelete>
					</Stack>
				</>	
			}
		</Box>
	</Dialog>
		<Dialog
				open={openTermsAndConditionsDialog}
				onClose={handleCloseTermsAndConditionsDialog}
				scroll='paper'
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					<Typography variant='h2'>
						{t('legals.termsAndConditions')}
					</Typography>
				</DialogTitle>
				<DialogContent dividers onScroll={handleTermsAndConditionsScroll}>
					<DialogContentText
						id="scroll-dialog-description">
						{t("legals.termsAndConditionsContent", { returnObjects: true })
							.map((item) => {return (
								<Grid
									container
									direction="column"
									sx={{
										justifyContent: "center",
										alignItems: "center",
										marginTop: '16px'
									}}
									>
									<Grid item>
										<Typography variant='h3'>
											{item.title}
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant='body1'>
											{item.content}
										</Typography>
									</Grid>
								</Grid>
							)
							})}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<StyledButtonAccept variant='contained' disabled={!readTermsAndConditions} onClick={() => handleAcceptTermsAndConditions()}>{t('legals.accept')}</StyledButtonAccept>
				</DialogActions>
			</Dialog>
			<Dialog
				open={openPrivacyPolicyDialog}
				onClose={handleClosePrivacyPolicyDialog}
				scroll='paper'
				aria-labelledby="scroll-dialog-title"
				aria-describedby="scroll-dialog-description"
			>
				<DialogTitle id="scroll-dialog-title">
					<Typography variant='h2'>
						{t('legals.privacyPolicy')}
					</Typography>
				</DialogTitle>
				<DialogContent dividers onScroll={handlePrivacyPolicyScroll}>
					<DialogContentText
						id="scroll-dialog-description">
						{t("legals.privacyPolicyContent", { returnObjects: true })
							.map((item) => {return (
								<Grid
									container
									direction="column"
									sx={{
										justifyContent: "center",
										alignItems: "center",
										marginTop: '16px'
									}}
									>
									<Grid item>
										<Typography variant='h3'>
											{item.title}
										</Typography>
									</Grid>
									<Grid item>
										<Typography variant='body1'>
											{item.content}
										</Typography>
									</Grid>
								</Grid>
							)
							})}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<StyledButtonAccept variant='contained' disabled={!readPrivacyPolicy} onClick={() => handleAcceptPrivacyPolicy()}>{t('legals.accept')}</StyledButtonAccept>
				</DialogActions>
			</Dialog>
			<Paper sx={{ marginRight:"1%", marginLeft: "1%", padding: "1%", paddingBottom: "24px!important", marginTop: '8px!important'}} className='CustomCard3' >
				{error && <Alert sx={{height: "auto!important", width: "100%!important", marginTop: "8px"}} severity='error'>{error}</Alert>}
				<Grid
					container
					direction="column"
					sx={{
						justifyContent: "center",
						alignItems: "center",
						marginTop: '16px'
					}}
					spacing={1}
					>
					<Grid item xs={12}>
						<Typography variant='h2'>
							{t('acceptNewPoliciesComponent.title')}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant='body1'>
							{t('acceptNewPoliciesComponent.description')}
						</Typography>
					</Grid>
				</Grid>
				<Grid
					container
					direction="column"
					spacing={0}
					>
					<Box component="form" noValidate onSubmit={handleSubmit}>
						<Grid item xs={12}>
							<Button sx={{width: '100%', justifyContent: 'flex-start'}} onClick={() => handleClickOpenTermsAndConditionsDialog()}>
								<FormControlLabel style={{marginRight: 3}}
									control={<Checkbox sx={{
											'&:hover': { backgroundColor: 'transparent' }
										}} value="open" color="primary" name="acceptsTermsAndConditionsDrawerCheckboxChecked" 
										checked={acceptsTermsAndConditions}/>}
										label={
											<Typography variant='body1' sx={{textTransform: 'none', textAlign: 'left'}}>
												{t('legals.acceptTermsAndConditions')}
											</Typography>}
										/>
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button sx={{width: '100%', justifyContent: 'flex-start'}} onClick={() => handleClickOpenPrivacyPolicyDialog()}>
								<FormControlLabel style={{marginRight: 3}}
									control={<Checkbox sx={{
											'&:hover': { backgroundColor: 'transparent' }
										}} value="open" color="primary" name="acceptsPrivacyPolicyDrawerCheckboxChecked" 
										checked={acceptsPrivacyPolicy}/>}
										label={
											<Typography variant='body1' sx={{textTransform: 'none', textAlign: 'left'}}>
												{t('legals.acceptPrivacyPolicy')}
											</Typography>}
									/>
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Button
								type="submit"
								fullWidth
								variant="contained"
								className="FlowButton"
								sx={{ margin: "16px!important", marginLeft: "0px!important", marginRight:"0px!important", borderRadius: "22px" }}
								disabled={loading || !acceptsTermsAndConditions || !acceptsPrivacyPolicy}
							>
								{t("accept")}
							</Button>
						</Grid>
						<Grid item xs={12}>
							<Typography variant='caption'>
								{t('acceptNewPoliciesComponent.alternativeToDeleteUser')}					
								<StyledButton onClick={handleDeleteUserModalOpen} variant="text" color="inherit">
									<Typography variant='caption'>	
										{t("acceptNewPoliciesComponent.alternativeToDeleteUserButton")}
									</Typography>		
								</StyledButton>
							</Typography>	
							<br/>	
							<RecaptchaNoticeComponent/>
						</Grid>
					</Box>
				</Grid>
			</Paper>
		</>
	)

}